import {def} from "../../../dictionnaire";

export const additif = {
    model: {},
    schema: [
        {
            component: "h3",
            children: "ADDITIF"
        },
        {
            name: "departementPublicationGroup",
            label: "Département de publication",
            type: "group",
            validation: "required",
            value: [{}],
            children: [
                def.departement
            ]
        },
        {
            name: "JournalPublicationAnnonceGroup",
            label: "Journal de publication de l'annonce",
            type: "group",
            value: [{}],
            children: [
                def.nomSociete,
                def.datePublication,
                def.journalPublication,
            ]
        },
        {
            name: "additifGroup",
            label: "Additif",
            type: "group",
            value: [{}],
            children: [
                def.additif
            ]
        },
        {
            type: "submit",
            label: "Save my social"
        }
    ],
    texte: "[[la société {{JournalPublicationAnnonceGroup.nomSociete}} est dans ]]le département {{departementPublicationGroup.departement}} [[créer le {{JournalPublicationAnnonceGroup.datePublication}}]]. Le journal {{JournalPublicationAnnonceGroup.journalPublication}} avec son additif : {{additifGroup.additif}}.",
};